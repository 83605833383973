import React from 'react';

const CardComponent: React.FC = () => {
    return (
        <div className='card-container'>
            <div className='card-content'>
                <div className='title-section'>
                    <div className='title'>Analytical scientific software</div>
                </div>
                <div className='description'>
                    Use specialized software to analyze data and manage research findings, to ensure experiments are accurate.
                </div>
            </div>
            <div className='skills-section'>
                <div className='skill-container'>
                    <div className='skill-checkbox' />
                    <div className='skill-text'>I’ve learnt this skill</div>
                </div>
            </div>
        </div>
    );
};

export default CardComponent;
