import React from 'react';

import messages from '../../../displayMessages';
import { useLayoutSize, WrappedMessage } from 'utils';
import Quote from './Quote';
import { CareerQuote, ProjectHighlight } from 'labxchange-client';
import { Icon } from 'elements';

interface HighlightSectionProps {
  children: React.ReactNode;
  showBackgroundEllipse?: boolean;
}

export const HighlightSection: React.FC<HighlightSectionProps> = ({ children, showBackgroundEllipse }) => {
  const isMobile = ['small', 'mobile', 'medium'].includes(useLayoutSize());

  if (!children) {
    return null;
  }

  return (
    <div className='highlight-container'>
      { children }
      {showBackgroundEllipse &&
        <div className='ellipse'>
          <Icon name='career-ellipse' zoom={isMobile ? '' : '35em'} />
        </div>
      }
    </div>
  );
};

interface ProjectHighlightProps {
  projectHighlight?: ProjectHighlight;
  careerQuote?: CareerQuote;
}

const ProjectHighlight: React.FC<ProjectHighlightProps> = ({ projectHighlight, careerQuote }) => {
  const quote = projectHighlight ? projectHighlight?.quote : careerQuote;
  const imageClass = projectHighlight ? 'highlight' : '';
  const iconColor = projectHighlight ? '#80D2C9' : '#E57295';

  if (!projectHighlight && !quote) {
    return null;
  }

  return (
    <HighlightSection showBackgroundEllipse={!projectHighlight}>
      <>
        {projectHighlight &&
          <div className='column project-highlight'>
            <div className='highlight-badge'>
              <h2 className='badge-text'>
                <WrappedMessage message={messages.projectHighlightSectionLabel} />
              </h2>
            </div>
            <div className='highlight-content'>
              <p className='highlight-title'>{projectHighlight.title}</p>
            </div>
            <div className='highlight-description'>{projectHighlight.description}</div>
          </div>
        }
        {quote &&
          <Quote quote={quote} imageClass={imageClass} iconColor={iconColor} />
        }
      </>
    </HighlightSection>
  );
};

export default ProjectHighlight;
