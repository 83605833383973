import React, { useState } from 'react';
import { Icon } from '../../../../../elements';

interface CollapsibleComponentProps {
    title: string;
    description: string;
    children?: React.ReactNode;
}

const CollapsibleComponent: React.FC<CollapsibleComponentProps> = ({ title, description, children }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className='collapsible-component'>
            <div className='collapsible-component-header' onClick={toggleExpand}>
                <h3 className='collapsible-component-heading'>{title}</h3>
                <button className='toggle-button'>
                    {isExpanded ?  <Icon name='chevron-up' fill={'#00AEEF'} zoom='1.25em' />
                        : <Icon name='chevron-down' fill={'#00AEEF'} zoom='1.25em' />
                    }
                </button>
            </div>
            {isExpanded && (
                <div className='expanded-content'>
                    <p>{description}</p>
                    {children}
                </div>
            )}
        </div>
    );
};

export default CollapsibleComponent;
