import React, { FC, useEffect, useState } from 'react';
import Select, { ValueType } from 'react-select';
import { intl } from 'i18n';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { StandardPageLayout, Spinner, Pagination } from 'ui/components';
import { CurriculumsApi, TaggedContentsApi } from 'global/api';
import {
  CurriculumsList, CurriculumTaggingDataItem, ItemMetadata,
  ModuleHierarchy, TaggedContentRequest, CurriculumSubject
} from 'labxchange-client';
import { detailUrlForEntity } from 'library/utils';
import { getLoggedInStatus, getUserPermissions } from 'auth/selectors';
import { ROUTES } from 'global/constants';

import { TagContentPageHeader } from '../TagContentPageHeader/TagContentPageHeader';
import { TagContentModal } from '../TagContentModal/TagContentModal';
import { SelectDropdownOption } from '../../../types';
import messages from '../../../displayMessages';

export const TagContentPage: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const curriculum = queryParams.get('curriculum');
  const subject = queryParams.get('subject');
  const page = queryParams.get('page') || '1';
  const pageSize = 10;

  const isLoggedIn = useSelector(getLoggedInStatus);
  const userPermissions = useSelector(getUserPermissions);
  const [isPermissionsLoading, setIsPermissionsLoading] = useState(true);

  const [curriculums, setCurriculums] = useState<CurriculumsList[]>([]);
  const [selectedCurriculum, setSelectedCurriculum] = useState<string>(curriculum || '');
  const [selectedSubject, setSelectedSubject] = useState<string>(subject || '');
  const [items, setItems] = useState<CurriculumTaggingDataItem[] | undefined>(undefined);
  const [modules, setModules] = useState<ModuleHierarchy[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [selectedItem, setSelectedItem] = useState<ItemMetadata>();
  const [showTagContentModal, setShowTagContentModal] = useState<boolean>(false);
  const [taggedItemIds, setTaggedItemIds] = useState<string[]>([]);
  const renderTagContentModal = showTagContentModal && selectedItem &&
    selectedCurriculum && selectedSubject;

  useEffect(() => {
    if (userPermissions === undefined) {
      setIsPermissionsLoading(true);
    } else {
      setIsPermissionsLoading(false);
    }
  }, [userPermissions]);

  useEffect(() => {
    const fetchCurriculums = async () => {
      try {
        setLoading(true);
        const response = await CurriculumsApi.list();
        setCurriculums(response);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchCurriculums();
  }, []);

  useEffect(() => {
    const fetchTaggingData = async () => {
      if (selectedCurriculum && selectedSubject && curriculums.length > 0) {
        try {
          setLoading(true);
          const response = await CurriculumsApi.getCurriculumSubjectTaggingData({
            curriculum: selectedCurriculum,
            subject: selectedSubject,
            page: parseInt(page, 10),
            pageSize,
          });
          if (response.results) {
            setItems(response.results.items);
            setModules(response.results.modules || []);
            setTotalItems(response.count);
          } else {
            setItems([]);
          }
          setLoading(false);
        } catch (error) {
          setItems([]);
          setLoading(false);
        }
      }
    };
    fetchTaggingData();
  }, [selectedCurriculum, selectedSubject, curriculums, page]);

  const clearState = () => {
    setItems(undefined);
    setModules([]);
    setTotalItems(0);
    setTaggedItemIds([]);
  };

  const createCurriculumSubjectOptions = () => {
    const curriculumOpts: SelectDropdownOption[] = [];
    const subjectOpts: SelectDropdownOption[] = [];

    curriculums.forEach((c: any) => {
      if (c.slug && c.name && c.isPublic) {
        curriculumOpts.push({
          value: c.slug,
          label: c.name + (c.country ? ` (${c.country.name})` : ''),
        });

        if (c.slug === selectedCurriculum) {
          c.subjects.forEach((s: CurriculumSubject) => {
            if (s.slug && s.name && s.isPublic) {
              subjectOpts.push({
                value: s.slug,
                label: s.name,
              });
            }
          });
        }
      }
    });

    return { curriculumOpts, subjectOpts };
  };

  const { curriculumOpts: curriculumOptions, subjectOpts: subjectOptions } = createCurriculumSubjectOptions();
  const curriculumValue = curriculumOptions.find((c) => c.value === selectedCurriculum);
  const subjectValue = subjectOptions.find((s) => s.value === selectedSubject);

  const updateQueryParams = (curriculumVal?: string, subjectVal?: string, pageNum?: string) => {
    const params = new URLSearchParams();
    if (curriculumVal) params.set('curriculum', curriculumVal);
    if (subjectVal) params.set('subject', subjectVal);
    if (pageNum) params.set('page', pageNum);
    history.push({ search: params.toString() });
  };

  const onCurriculumChange = (selectedOption: ValueType<SelectDropdownOption>) => {
    if (selectedOption) {
      const newCurriculum = selectedOption as SelectDropdownOption;
      if (newCurriculum.value === selectedCurriculum) return;
      setSelectedCurriculum(newCurriculum.value);
      clearState();
      setSelectedSubject('');
      updateQueryParams(newCurriculum.value);
    }
  };

  const onSubjectChange = (selectedOption: ValueType<SelectDropdownOption>) => {
    if (selectedOption && selectedCurriculum) {
      const newSubject = selectedOption as SelectDropdownOption;
      if (newSubject.value === selectedSubject) return;
      clearState();
      setSelectedSubject(newSubject.value);
      updateQueryParams(selectedCurriculum, newSubject.value);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }
  , [page]);

  const onPageChange = (newPage: number) => {
    updateQueryParams(selectedCurriculum, selectedSubject, newPage.toString());
  };

  const onClickTagAsset = (item: ItemMetadata) => {
    setSelectedItem(item);
    setShowTagContentModal(true);
  };

  const onCloseTagContentModal = () => {
    setShowTagContentModal(false);
  };

  const onSubmitTagContentModal = async (data: TaggedContentRequest) => {
    const response = await TaggedContentsApi.contentsCreate({ data });
    setTaggedItemIds([...taggedItemIds, response.asset]);
  };

  const renderAssetTable = () => (
    <table className='tag-content-table'>
      <thead>
        <tr>
          <th>{intl.formatMessage(messages.tagContentTableAssetTitle)}</th>
          <th>{intl.formatMessage(messages.tagContentTableTagContent)}</th>
          <th>{intl.formatMessage(messages.tagContentTableStatus)}</th>
        </tr>
      </thead>
      <tbody>
        {items && items.map((item) => {
          const isItemTagged = item.status || taggedItemIds.includes(item.metadata.id);
          const tagBtnLabel = isItemTagged ?
            messages.tagContentTableReTagBtnLabel : messages.tagContentTableTagBtnLabel;

          return (
            <tr key={item.metadata.id}>
              <td>
                <a href={`${detailUrlForEntity(item.metadata)}`} target='_blank' rel='noreferrer'>
                  {item.metadata.title}
                </a>
              </td>
              <td>
                <button
                  className={`tag-content-btn ${isItemTagged ? 'tagged' : ''}`}
                  onClick={() => onClickTagAsset(item.metadata)}
                  aria-label={intl.formatMessage(tagBtnLabel)}
                >
                  {intl.formatMessage(tagBtnLabel)}
                </button>
              </td>
              <td className={isItemTagged ? 'status-tagged' : 'status-not-tagged'}>
                {isItemTagged
                  ? intl.formatMessage(messages.tagContentTableTaggedStatusLabel)
                  : intl.formatMessage(messages.tagContentTableNotTaggedStatusLabel)
                }
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );

  const tagContentDropdowns = (
    <>
      <Select
        key={`curriculum_dropdown_${selectedCurriculum}`}
        placeholder={intl.formatMessage(messages.tagContentCurriculumDropdownPlaceholder)}
        aria-label={intl.formatMessage(messages.tagContentCurriculumDropdownAria)}
        tabIndex='0'
        className='select-dropdown'
        classNamePrefix='select'
        options={curriculumOptions}
        onChange={onCurriculumChange}
        value={curriculumValue}
        isDisabled={loading}
        isSearchable
      />
      <Select
        key={`subject_dropdown_${selectedSubject}`}
        placeholder={intl.formatMessage(messages.tagContentSubjectDropdownPlaceholder)}
        aria-label={intl.formatMessage(messages.tagContentSubjectDropdownAria)}
        tabIndex='0'
        className='select-dropdown'
        classNamePrefix='select'
        options={subjectOptions}
        onChange={onSubjectChange}
        value={subjectValue}
        isDisabled={loading || !selectedCurriculum}
        isSearchable
      />
    </>
  );

  let content = (
    <div className='tag-content-wrapper'>
      <div className='font-xxs-lt'>
        <p>
          {intl.formatMessage(messages.tagContentPageTotalAssets, { count: totalItems })}
        </p>
      </div>
      {items === undefined ? <p>{intl.formatMessage(messages.tagContentSelectCurriculumAndSubject)}</p> : (
        items.length > 0 ? renderAssetTable()
         : <p>{intl.formatMessage(messages.tagContentNoItemsFound)}</p>
      )}
    </div>
  );

  if (!isLoggedIn) {
    return <Redirect to={ROUTES.All.HOME} push />;
  }

  if (isPermissionsLoading || loading) {
    content = <div data-testid='tag-content-spinner' className='spinner-wrapper'><Spinner /></div>;
  } else if (!userPermissions?.explore?.canUserTagContent) {
    return <Redirect to={ROUTES.All.HOME} push />;
  }

  return (
    <StandardPageLayout
      backgroundStyle='light-header'
      mainClassName='tag-content-page'
      headerFeature={<TagContentPageHeader dropdowns={tagContentDropdowns} />}
    >
      {content}
      {renderTagContentModal && curriculumValue && subjectValue && (
        <TagContentModal
          item={selectedItem}
          curriculum={curriculumValue}
          subject={subjectValue}
          modules={modules}
          onClose={onCloseTagContentModal}
          onSubmit={onSubmitTagContentModal}
        />
      )}
      {!loading && items && items.length > 0 &&
        <Pagination
          pageCount={Math.ceil(totalItems / pageSize)}
          currentPage={parseInt(page, 10)}
          onPageSelect={onPageChange}
        />
      }
    </StandardPageLayout>
  );
};
