// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    CareerQuote,
    CareerQuoteFromJSON,
    CareerQuoteToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProjectHighlight
 */
export interface ProjectHighlight {
    /**
     * 
     * @type {CareerQuote}
     * @memberof ProjectHighlight
     */
    quote?: CareerQuote;
    /**
     * Project highlight title.
     * @type {string}
     * @memberof ProjectHighlight
     */
    title: string;
    /**
     * Project highlight description.
     * @type {string}
     * @memberof ProjectHighlight
     */
    description: string;
}

export function ProjectHighlightFromJSON(json: any): ProjectHighlight {
    return {
        'quote': !exists(json, 'quote') ? undefined : CareerQuoteFromJSON(json['quote']),
        'title': json['title'],
        'description': json['description'],
    };
}

export function ProjectHighlightToJSON(value?: ProjectHighlight): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'quote': CareerQuoteToJSON(value.quote),
        'title': value.title,
        'description': value.description,
    };
}


