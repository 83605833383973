// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface Task
 */
export interface Task {
    /**
     * The long name of the task.
     * @type {string}
     * @memberof Task
     */
    longName: string;
    /**
     * Description of the task.
     * @type {string}
     * @memberof Task
     */
    description: string;
    /**
     * Abilities required to perform the task.
     * @type {any}
     * @memberof Task
     */
    abilities?: any;
    /**
     * Skills required to perform the task.
     * @type {any}
     * @memberof Task
     */
    skills?: any;
    /**
     * The duty associated with this task.
     * @type {number}
     * @memberof Task
     */
    duty: number;
}

export function TaskFromJSON(json: any): Task {
    return {
        'longName': json['long_name'],
        'description': json['description'],
        'abilities': !exists(json, 'abilities') ? undefined : (json['abilities']),
        'skills': !exists(json, 'skills') ? undefined : (json['skills']),
        'duty': json['duty'],
    };
}

export function TaskToJSON(value?: Task): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'long_name': value.longName,
        'description': value.description,
        'abilities': (value.abilities),
        'skills': (value.skills),
        'duty': value.duty,
    };
}


