import React, { useState } from 'react';
import { intl } from 'i18n';
import { Icon } from '../../../../elements';
import { useLayoutSize, WrappedMessage } from '../../../../utils';
import messages from '../../../../career-explorer/displayMessages';
import classNames from 'classnames';
import BasicsPill from './BasicsPill';

interface CareerIntroductionProps {
    description: string;
    imageUrl?: string;
    pronunciation: string;
    pronunciationAudio?: string;
    alternativeNames: string[];
}

// Dummy Data for the quick looks section
const quickLookData = [
    {
        title: 'Place of work',
        info: 'Research laboratory',
        description: 'Research laboratory is the place of work',
        icon: '/assets/images/career-explorer/biotech.svg',
    },
    {
        title: 'Most frequent task',
        info: 'Collect samples',
        description: 'Collect samples for testing ',
        icon: '/assets/images/career-explorer/biotech.svg',
    },
    {
        title: 'Career level',
        info: 'Entry level',
        description: 'Entry level education is a must',
        icon: '/assets/images/career-explorer/biotech.svg',
    },
    {
        title: 'Minimum education required',
        info: 'Bachelor’s Degree',
        description: 'Bachelor’s Degree is a must to enter this career',
        icon: '/assets/images/career-explorer/biotech.svg',
    },
    {
        title: 'Demand',
        info: 'Rapid growth',
        description: 'Rapid growth is expected in this career if you have advanced education',
        icon: '/assets/images/career-explorer/biotech.svg',
    },
    {
        title: 'Average salary',
        info: '$36K to $65K',
        description: '$36K to $65K is average salary for an experienced person',
        icon: '/assets/images/career-explorer/biotech.svg',
    },
];

const CareerIntroduction: React.FC<CareerIntroductionProps> = ({
 description,
 imageUrl,
 pronunciation,
 pronunciationAudio,
 alternativeNames,
}) => {

    const [showAllNames, setShowAllNames] = useState(false);
    const [isAudioPlaying, setIsAudioPlaying] = useState(false);

    const isMobile = ['small', 'mobile'].includes(useLayoutSize());
    const isMobileOrTablet = ['small', 'mobile', 'medium'].includes(useLayoutSize());

    const handleShowMore = () => {
        setShowAllNames(!showAllNames);
    };

    const toggleAudio = () => {
        if (pronunciationAudio) {
            const audio = new Audio(pronunciationAudio);
            if (!isAudioPlaying) {
                audio.play();
                setIsAudioPlaying(true);
                audio.onended = () => setIsAudioPlaying(false);
            }
        }
    };

    const defaultCountToShow = 2; // Show first 2 names by default
    const displayedNames = showAllNames
        ? alternativeNames
        : alternativeNames?.slice(0, defaultCountToShow);

    const CareerIllustration = () => (<div className='image-container'>
        <img
            className='career-icon'
            alt={intl.formatMessage(messages.altTextForIllustration)}
            src={imageUrl}
        />
    </div>);

    return (
        <div className='career-intro'>
            <div className={classNames({'career-introduction-desktop': !isMobileOrTablet })}>
                <div className={classNames('career-introduction', {'text-content': !isMobileOrTablet})}>
                    <p>{description}</p>

                    {isMobileOrTablet && imageUrl &&
                      <CareerIllustration />
                    }

                {pronunciation &&
                    <div className={classNames('pronunciation-container', {'wd-50': !imageUrl && !isMobile})}>
                        <button onClick={toggleAudio} className='pronunciation' data-testid='pronunciation-button'>
                            <div className='pronunciation-icon'>
                                <Icon name={'volume-up'} fill={'white'} zoom='2em' />
                            </div>
                            <div className='pronunciation-description {'>
                                <span className='pronunciation-region'>
                                    <WrappedMessage message={messages.careerPronunciationAmericanText} />
                                </span>
                                <span className='pronunciation-text'>{pronunciation}</span>
                            </div>
                            {isAudioPlaying && <div className='pulse'></div>}
                        </button>
                    </div>
                }
                </div>

                {!isMobileOrTablet && imageUrl &&
                  <div className={classNames({'image-content': !isMobileOrTablet})}>
                    <CareerIllustration/>
                  </div>
                }
            </div>
            {alternativeNames &&
                <div className='alternative-names'>
                    <span className='alternative-names-heading'>
                        <WrappedMessage message={messages.careerAlternativeNamesText} />
                    </span>
                    <p>
                        {displayedNames && displayedNames.join(', ')}
                        {alternativeNames.length > defaultCountToShow && (
                            <>
                                {showAllNames ? (
                                    <button className='expand-button' onClick={handleShowMore}>
                                        <WrappedMessage message={messages.showLessNamesText} />
                                    </button>
                                ) : (
                                    <button className='expand-button' onClick={handleShowMore}>
                                        <WrappedMessage
                                            message={messages.showAllNamesText}
                                            values={{count: alternativeNames.length - defaultCountToShow}}
                                        />
                                    </button>
                                )}
                            </>
                        )}
                    </p>
                </div>
            }
            {/* Quick Look Section */}
            <div className='quick-look-section'>
                <h3>A quick look at the basics</h3>
                <div className='quick-look-container'>
                    {quickLookData.map((item, index) => (
                        <BasicsPill key={index} title={item.title} description={item.description} icon={item.icon} info={item.info}/>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CareerIntroduction;
