// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface TaggedContentRequest
 */
export interface TaggedContentRequest {
    /**
     * 
     * @type {string}
     * @memberof TaggedContentRequest
     */
    userEmail: string;
    /**
     * 
     * @type {string}
     * @memberof TaggedContentRequest
     */
    itemId: string;
    /**
     * 
     * @type {string}
     * @memberof TaggedContentRequest
     */
    curriculum: string;
    /**
     * 
     * @type {string}
     * @memberof TaggedContentRequest
     */
    subject: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaggedContentRequest
     */
    modules: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof TaggedContentRequest
     */
    isAssetRelevant: boolean;
    /**
     * 
     * @type {string}
     * @memberof TaggedContentRequest
     */
    tellUsMoreFeedback?: string;
}

export function TaggedContentRequestFromJSON(json: any): TaggedContentRequest {
    return {
        'userEmail': json['user_email'],
        'itemId': json['item_id'],
        'curriculum': json['curriculum'],
        'subject': json['subject'],
        'modules': json['modules'],
        'isAssetRelevant': json['is_asset_relevant'],
        'tellUsMoreFeedback': !exists(json, 'tell_us_more_feedback') ? undefined : json['tell_us_more_feedback'],
    };
}

export function TaggedContentRequestToJSON(value?: TaggedContentRequest): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'user_email': value.userEmail,
        'item_id': value.itemId,
        'curriculum': value.curriculum,
        'subject': value.subject,
        'modules': value.modules,
        'is_asset_relevant': value.isAssetRelevant,
        'tell_us_more_feedback': value.tellUsMoreFeedback,
    };
}


