import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { EnableLxLoginEnum, LxConfig, ROUTES } from 'global/constants';
import { LoginView } from 'auth/components/LoginView';
import { LXSignUpView } from 'auth/components/LxSignUpView';
import { ActivateAccount } from 'auth/components/ActivateAccount';
import { BookPage } from 'books/components';
import { ClassroomPage } from 'classrooms/components';
import { ClustersPage } from 'clusters/components';
import { NotificationPage } from 'notifications/components';
import { CommunityPage, DashboardPage, OrganizationDashboardPage } from 'dashboard';
import { ExplorePage } from 'explore/components';
import { SubjectsPage } from 'subjects/components/page';
import { About, Faq, Home } from 'home/components';
import { TagContentPage } from 'curriculum';

import {
    ItemEditOlxPageWrapper,
    ItemEditPage,
    ItemPage,
    LibraryPage,
    PathwayEditPage,
    PathwayItemPage,
    PathwayPage,
    PeoplePage,
    RedirectToPathwayItemPage,
    OrganizationSearchPage,
} from 'library/components';
import { CollaboratorsPage, OrgProfilePage } from 'org/components';
import { UserProfilePage, UserProfileEditPage } from 'user/components/UserProfile';
import { UserProfileSettings } from 'user/components/UserProfileSettings';
import { OnboardingPage } from 'user/components/UserRegistration/OnboardingPage';
import { SignupPage } from 'user/components/UserRegistration/SignupPage';
import { RedirectPage } from './RedirectPage';
import { InitiativePage } from 'initiatives';
import { DiscussionThread } from 'discussions/components/DiscussionThread';
import {
    DiscussionCategoryPage,
    DiscussionHome,
    DiscussionSubCategoryPage,
} from 'discussions/components';
import { ResetPasswordEmailView, ResetPasswordView } from 'auth/components/ResetPassword';
import { LXLoginView } from 'auth/components/LxLoginView';
import { AuthFailView } from 'auth/components/AuthFailView';
import { SSOLogin } from 'auth/components/SSOLogin';
import { PasswordLoginView } from 'auth/components/LxLoginView/PasswordLoginView';
import { TopicPage } from 'subjects/components/page/TopicPage';
import { SubjectPage } from 'subjects/components/page/SubjectPage';
import { ContentPage } from 'subjects/components/page/ContentPage';
import { TeachPage } from 'home/components/TeachPage';
import { CurriculumsPage, CurriculumTopicPage } from 'curriculum/components';
import { SubCategoryHome } from '../career-explorer/components-v2/SubCategoryHome/SubCategoryHome';
import CareersPage from '../career-explorer/components-v2/Careers/CareersPage';
import HomePage from 'career-explorer/components-v2/Home';
import CareerDetailsPage from 'career-explorer/components-v2/Careers/CareerDetailsPage/CareerDetailsPage';


// To set the title and description of the link preview for any of these links, please set up the
// url in `page_metadata` app on backend. Add a url and in urls.py and a relevant view in views.py.
// Also if any of the urls here change, you may also need to update them
// in this `page_metadata` urls.py file to make sure the link preview works.

export const ContentRoutes = () => {
    const lxLoginView = LxConfig.EnableLxLogin === EnableLxLoginEnum.Production ? LXLoginView : LoginView;
    const lxSignUpView = LxConfig.EnableLxLogin === EnableLxLoginEnum.Production ? LXSignUpView : SignupPage;
    return (
        <Switch>
            <Route path={ROUTES.General.ABOUT} component={About} />
            <Route path={ROUTES.General.FAQ} component={Faq} />
            {LxConfig.EnableLxLogin !== EnableLxLoginEnum.None ? <Route path={ROUTES.General.LX_SIGN_IN} component={LXLoginView} /> : null}
            {LxConfig.EnableLxLogin !== EnableLxLoginEnum.None ? <Route path={ROUTES.General.LOG_IN} component={LXLoginView} /> : null}
            <Route path={ROUTES.General.SIGN_IN} component={lxLoginView} />
            <Route path={ROUTES.General.SIGN_UP} component={lxSignUpView} />
            <Route path={ROUTES.General.SSO_AUTH_PAGE} component={SSOLogin} />
            <Route path={ROUTES.General.PASSWORD_AUTH_PAGE} component={PasswordLoginView} />
            {LxConfig.EnableLxLogin === EnableLxLoginEnum.Debug ? <Route path={ROUTES.General.LX_SIGN_UP} component={LXSignUpView} /> : null}
            <Route path={ROUTES.General.EMAIL_CONFIRMATION} component={ActivateAccount} />
            <Route path={ROUTES.General.RESET_PASSWORD} component={ResetPasswordView} />
            <Route path={ROUTES.General.RESET_PASSWORD_EMAIL} component={ResetPasswordEmailView} />
            <Route path={ROUTES.General.SIGNUP_DENIED_AGE}
                render={() => <AuthFailView  type='age'/>} />
            <Route path={ROUTES.General.SIGNUP_DENIED_SDN}
                render={() => <AuthFailView  type='sdn'/>} />
            <Route path={ROUTES.General.DENIED_BLOCKED}
                render={() => <AuthFailView  type='blocked'/>} />
            <Route path={ROUTES.General.DENIED_COUNTRY}
                render={() => <AuthFailView  type='country'/>} />
            <Route path={ROUTES.General.ONBOARDING} component={OnboardingPage} />
            <Route path={ROUTES.Explore.HOME} component={ExplorePage} />
            <Route path={ROUTES.Subjects} component={SubjectsPage} />
            <Route path={ROUTES.Subject.HOME} component={SubjectPage} exact />
            <Route path={ROUTES.Subject.CONTENT} component={ContentPage} exact />
            <Route path={ROUTES.Topic} component={TopicPage} />
            <Route path={ROUTES.CurriculumsPage} component={CurriculumsPage} exact={true}/>
            <Route path={ROUTES.CurriculumModule} component={CurriculumTopicPage} exact={true}/>
            <Route path={ROUTES.TagContent.HOME} component={TagContentPage} exact />
            <Route path={ROUTES.Library.BOOK} component={BookPage} exact={true} />
            <Route path={ROUTES.Library.CLUSTER} component={ClustersPage} />
            <Route path={ROUTES.Library.PATHWAY_NEW} component={PathwayEditPage} />
            <Route path={ROUTES.Library.PATHWAY_EDIT} component={PathwayEditPage} />
            <Route path={ROUTES.Library.PATHWAY_ITEM} component={PathwayItemPage} />
            <Route path={ROUTES.Library.PATHWAY_ITEM_ALT} component={RedirectToPathwayItemPage} />
            <Route path={ROUTES.Library.PATHWAY} component={PathwayPage} />
            <Route path={ROUTES.Library.HOME} component={LibraryPage} exact={true} />
            <Route path={ROUTES.Library.ITEM_NEW} component={ItemEditPage} />
            <Route path={ROUTES.Library.ITEM_EDIT} component={ItemEditPage} exact={true} />
            <Route path={ROUTES.Library.ITEM_EDIT_OLX} component={ItemEditOlxPageWrapper} exact={true} />
            <Route path={ROUTES.Library.ITEM} component={ItemPage} />
            <Route path={ROUTES.Messages.MESSAGE_NEW} component={DashboardPage} />
            <Route path={ROUTES.Messages.MESSAGE} component={DashboardPage} />
            <Route path={ROUTES.Messages.HOME} component={DashboardPage} />
            <Route path={ROUTES.Dashboard.ORGANIZATION_CONTENT} component={OrganizationDashboardPage} />
            <Route path={ROUTES.Dashboard.LEARNER_CLASSROOMS} component={DashboardPage} />
            <Route path={ROUTES.Dashboard.EDUCATOR_CLASSROOMS} component={DashboardPage} />
            <Route path={ROUTES.Dashboard.FAVORITES} component={DashboardPage} />
            <Route path={ROUTES.Dashboard.HOME} component={DashboardPage} />
            <Route path={ROUTES.Community.MENTEES} component={CommunityPage} />
            <Route path={ROUTES.Community.MENTORS} component={CommunityPage} />
            <Route path={ROUTES.Community.SUBCATEGORY_THREAD_ROUTE} component={DiscussionThread} />
            <Route path={ROUTES.Community.SUBCATEGORY_ROUTE} component={DiscussionSubCategoryPage} />
            <Route path={ROUTES.Community.CATEGORY_ROUTE} component={DiscussionCategoryPage} />
            <Route path={ROUTES.Community.DISCUSSIONS} component={DiscussionHome} />
            <Route path={ROUTES.Community.HOME} component={CommunityPage} />
            <Route path={ROUTES.Classrooms.CLASSROOM_DISCUSSION_THREAD_POST} component={ClassroomPage} />
            <Route path={ROUTES.Classrooms.CLASSROOM_DISCUSSION_THREAD} component={ClassroomPage} />
            <Route path={ROUTES.Classrooms.CLASSROOM_DISCUSSION} component={ClassroomPage} />
            <Route path={ROUTES.Classrooms.CLASSROOM_LEARNERS} component={ClassroomPage} />
            <Route path={ROUTES.Classrooms.CLASSROOM_PROGRESS} component={ClassroomPage} />
            <Route path={ROUTES.Classrooms.CLASSROOM} component={ClassroomPage} />
            <Route path={ROUTES.People.HOME} component={PeoplePage} />
            <Route path={ROUTES.Organizations.HOME} component={OrganizationSearchPage} />
            <Route path={ROUTES.Users.PROFILE} component={UserProfilePage} />
            <Route path={ROUTES.Users.PROFILE_EDIT} component={UserProfileEditPage} />
            <Route path={ROUTES.Organizations.PROFILE_FILTERED} component={OrgProfilePage} />
            <Route path={ROUTES.Organizations.PROFILE} component={OrgProfilePage} />
            <Route path={ROUTES.Organizations.COLLABORATORS} component={CollaboratorsPage} />
            <Route path={ROUTES.Personal.PROFILE_SETTINGS} component={UserProfileSettings} />
            <Route path={ROUTES.Personal.NOTIFICATIONS} component={NotificationPage} />
            <Route path={ROUTES.Redirects.REDIRECT} component={RedirectPage} />
            <Redirect exact strict from={ROUTES.Redirects.ABE_CLUSTER_CUSTOM}
                to={ROUTES.Library.CLUSTER_SLUG('lx-cluster\\:abe')}
            />
            <Route path={ROUTES.Teach} component={TeachPage} />

            <Route path={ROUTES.CareerExplorer.HOME} component={HomePage} exact />
            <Route path={ROUTES.CareerExplorer.CAREER_SUBCATEGORY_URL} component={SubCategoryHome} exact />
            <Route path={ROUTES.CareerExplorer.CAREER_LIST_URL} component={CareersPage} exact />
            <Route path={ROUTES.CareerExplorer.CAREER_DETAILS} component={CareerDetailsPage} exact />

            {/*these two should always remain at end of url declarations to avoid any confusions*/}
            <Route path={ROUTES.Initiatives.ALL} component={InitiativePage} />
            <Route path={ROUTES.All.HOME} component={Home} />
        </Switch>
    );
};
