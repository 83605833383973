import { defineMessages } from 'react-intl';

const messages = defineMessages({
    footerAccessibility: {
        id:'footerAccessibility',
        defaultMessage: 'Accessibility',
        description: 'Text for the footer accessibility link.',
    },
    footerBlog: {
        id:'footerBlog',
        defaultMessage: 'Blog',
        description: 'Text for the blog footer link.',
    },
    footerEvents: {
        id:'footerEvents',
        defaultMessage: 'Events',
        description: 'Text for the events footer link.',
    },
    footerCaptionQuickLinks: {
        id:'footerCaptionQuickLinks',
        defaultMessage: 'Quick links',
        description: 'Title for the quick links group in the footer.',
    },
    footerMyClasses: {
        id:'footerMyClasses',
        defaultMessage: 'My classes',
        description: 'Text for the footer my classes link.',
    },
    footerMyContent: {
        id:'footerMyContent',
        defaultMessage: 'My content',
        description: 'Text for the footer my content link.',
    },
    footerContactUs: {
        id:'footerContactUs',
        defaultMessage: 'Contact us',
        description: 'Text for the contact us link.',
    },
    footerCopyright: {
        id:'footerCopyright',
        defaultMessage: '© {year} The President and Fellows of Harvard College',
        description: 'Text for the copyright notice for the legal/business name for Harvard University.',
    },
    footerDiscussion: {
        id:'footerDiscussion',
        defaultMessage: 'Discussion',
        description: 'Text for the discussion footer link.',
    },
    footerFAQ: {
        id:'footerFAQ',
        defaultMessage: 'FAQ',
        description: 'Text for the FAQ footer link.',
    },
    footerHelpCenter: {
        id:'footerHelpCenter',
        defaultMessage: 'Help Center',
        description: 'Text for the help center footer link.',
    },
    footerLibrary: {
        id:'footerLibrary',
        defaultMessage: 'Library',
        description: 'Text for the library footer link.',
    },
    footerPrivacyPolicy: {
        id:'footerPrivacyPolicy',
        defaultMessage: 'Privacy notice',
        description: 'Text for the privacy policy footer link.',
    },
    footerPrivacyAndSecurity: {
        id:'footerPrivacyAndSecurity',
        defaultMessage: 'Privacy & Security',
        description: 'Text for link to a page which provides details about privacy and security policies and practices',
    },
    footerResources: {
        id:'footerResources',
        defaultMessage: 'About us',
        description: 'Text for the footer about us group.',
    },
    footerPeople: {
        id:'footerPeople',
        defaultMessage: 'People',
        description: 'Text for the people footer link.',
    },
    footerNews: {
        id: 'footerNews',
        defaultMessage: 'News',
        description: 'Text for the news footer link'
    },
    footerPrivacyLink: {
        id:'footerPrivacyLink',
        defaultMessage: 'Privacy notice',
        description: 'Text for the privacy policy footer link.',
    },
    footerAboutLink: {
        id:'footerAboutLink',
        defaultMessage: 'About',
        description: 'Text for the footer about group.',
    },
    footerShareStory: {
        id:'footerShareStory',
        defaultMessage: 'Share your story',
        description: 'Text for the "Share your story" link.',
    },
    footerStayConnected: {
        id:'footerStayConnected',
        defaultMessage: 'Connect',
        description: 'Text for the footer connect group.',
    },
    footerSupport: {
        id:'footerSupport',
        defaultMessage: 'Help',
        description: 'Text for the footer help group.',
    },
    footerCollaborators: {
        id:'footerCollaborators',
        defaultMessage: 'Collaborators',
        description: 'Text for the footer collaborators link',
    },
    footerTeam: {
        id:'footerTeam',
        defaultMessage: 'Co-developers',
        description: 'Text for the team footer link.',
    },
    footerTermsOfService: {
        id:'footerTermsOfService',
        defaultMessage: 'Terms of service',
        description: 'Text for the terms and conditions footer link.',
    },
    footerTutorials: {
        id:'footerTutorials',
        defaultMessage: 'Tutorials',
        description: 'Text for the tutorials footer link.',
    },
    footerGettingStarted: {
        id:'footerGettingStarted',
        defaultMessage: 'Get started',
        description: 'Text for the getting started footer link.',
    },
    footerTeacherResources: {
        id:'footerTeacherResources',
        defaultMessage: 'Teacher resources',
        description: 'Text for the teacher resources footer link.',
    },
    footerCommunity: {
        id: 'footerCommunity',
        defaultMessage: 'Community',
        description: 'Text for community footer link',
    },
    footerMessages: {
        id: 'footerMessages',
        defaultMessage: 'Messages',
        description: 'Text for messages footer link',
    },
    footerExplore: {
        id: 'footerExplore',
        defaultMessage: 'Explore',
        description: 'Text for explore footer link',
    },
});

export default messages;
