import React from 'react';
import { WrappedMessage } from '../../../../../utils';
import messages from '../../../../displayMessages';
import CareerTasks from './CareerTasks';
import { ChallengeCard } from './ChallengeCard';
import { CareerSuggestedAssets, CareerDuty } from 'labxchange-client';
import { Icon } from '../../../../../elements';

interface CareerDutiesProps {
    duty: CareerDuty;
    suggestedAssets?: CareerSuggestedAssets[];
}

const CareerDuties: React.FC<CareerDutiesProps> = ({
  duty,
  suggestedAssets,
}) => {

    return (
       <div className='career-duties'>
           <h2> <WrappedMessage message={messages.CareerDutiesHeadingText} /> </h2>
           <div className='career-duties-description'>
               <WrappedMessage message={messages.CareerDutiesIntroText} />
           </div>

           {duty.tasks.length &&
             <CareerTasks
               tasks={duty.tasks}
               backgroundImage={duty.backgroundImage}
             />
           }

           {duty.insights &&
             <div className='career-duties-insight'>
               <Icon name='info' zoom='1em' fill={'#00AEEF'} />
               <div className='insight'>
                   {duty.insights}
               </div>
             </div>
           }

           <div className='divider' data-testid='divider'></div>

           <div>
               {suggestedAssets &&
                 <>
                   <div className='challenge-heading'>
                     <WrappedMessage message={messages.careerChallengeHeading}/>
                   </div>
                     {
                         suggestedAssets.map(asset => (
                             <ChallengeCard suggestedAsset={asset}/>
                         ))
                     }
                 </>
               }
           </div>
       </div>
    );
};

export default CareerDuties;
