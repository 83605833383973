import React from 'react';
import CareerOutlookFact from './CareerOutlookFact';
import { Icon, IconSymbol } from 'elements';
import { CareerOutlook } from 'labxchange-client';

import messages from '../../../../career-explorer/displayMessages';
import { WrappedMessage } from 'utils';
import { MessageDescriptor } from 'react-intl';

interface CareerOutlookCardProps {
  title: MessageDescriptor;
  subTitle: number | string;
  iconName: IconSymbol;
}

export const CareerOutlookCard: React.FC<CareerOutlookCardProps> = ({ title, subTitle, iconName }) => {
  return (
    <div className='outlook-card'>
      <div className='outlook-card-icon'>
        <Icon name={iconName} fill='white' zoom='2em'/>
      </div>
      <div className='outlook-card-content'>
        <div className='outlook-card-title'>
          <WrappedMessage message={title} />
        </div>
        <div className='outlook-card-subtitle'>{subTitle}</div>
      </div>
    </div>
  );
};


interface CareerOutlookProps {
  outlook: CareerOutlook;
}

const CareerOutlook: React.FC<CareerOutlookProps> = ({ outlook }) => {
  return (
    <div className='career-outlook-container'>
      <div className='title-container'>
        <div className='title'>
          <WrappedMessage message={messages.outlookSectionHeading} />
        </div>
      </div>
      <div className='cards-container'>
        <CareerOutlookCard title={messages.outlookDemandTitle} subTitle={outlook.demandLabel} iconName='demand' />
        {outlook.jobOpenings !== undefined && outlook.jobOpenings > 0 &&
          <CareerOutlookCard title={messages.outlookJobOpeningsTitle} subTitle={outlook.jobOpenings} iconName='jobs' />
        }
      </div>
      <div className='quote'>
        <span><WrappedMessage message={messages.outlookQouteTextPart1} />, </span>
        <span className='highlight'>“<WrappedMessage message={messages.outlookQouteTextPart2} />”</span>
      </div>
      {outlook.facts &&
        <div className='fact-cards'>
        {outlook.facts.map((fact, index) => (
            <CareerOutlookFact key={index} cardNumber={index + 1} fact={fact} />
        ))}
      </div>
      }
    </div>
  );
};

export default CareerOutlook;
