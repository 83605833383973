import React, { useState } from 'react';
import { Icon } from 'elements';

import messages from '../../../../career-explorer/displayMessages';
import { WrappedMessage } from 'utils';

interface SalaryCardProps {
  salaryLevels: {
    entry: number | undefined,
    mid: number | undefined,
    senior: number | undefined,
  };
}

export const SalaryCard: React.FC<SalaryCardProps> = ({
  salaryLevels,
}) => {
  const [sliderLevel, setSliderLevel] = useState(1);
  const [salary, setSalary] = useState(salaryLevels.entry);
  const [salaryLevel, setSalaryLevel] = useState(messages.salaryEntryLevelLabel);

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const sliderLevelValue = parseInt(event.target.value, 0);
    setSliderLevel(sliderLevelValue);

    switch (sliderLevelValue) {
      case 1:
        setSalaryLevel(messages.salaryEntryLevelLabel);
        return setSalary(salaryLevels.entry);
      case 2:
        setSalaryLevel(messages.salarySliderMidLevelText);
        return setSalary(salaryLevels.mid);
      case 3:
        setSalaryLevel(messages.salarySeniorLevelLabel);
        return setSalary(salaryLevels.senior);
    }
  };

  return (
    <div className='salary-card'>
      <div className='header'>
        <div className='title'>
          <WrappedMessage message={salaryLevel} />
        </div>
        <div className='salary-info'>
          <div className='salary-icon'>
            <Icon name='salary' fill='white' zoom='2em'/>
          </div>
          <div className='salary-text'>
            <div className='salary-label'>
              <WrappedMessage message={messages.averageSalaryLabel} />
            </div>
            <div className='salary-amount'>
              ${salary?.toLocaleString()} <WrappedMessage message={messages.averageSalaryPerYearLabel} />
            </div>
          </div>
        </div>
      </div>
      <div className='divider'></div>
      <div className='content'>
        <div className='content-text'>
          <WrappedMessage message={messages.salarySliderHelpText} />
        </div>
        <div className='slider-container'>
          <div className='slider-dots'>
            {[...Array(3).keys()].map((num) =>
              <div key={num} className={`slider-dot ${sliderLevel === num + 1 ? 'active': ''}`}></div>
            )}
          </div>
          <input
            type='range'
            min='1'
            max='3'
            value={sliderLevel}
            onChange={handleSliderChange}
            className='slider'
          />
          <div className='salary-levels'>
            <div className='level entry'>
              <span className='highlight'>
                <WrappedMessage message={messages.salarySliderEntryLevelText} />
              </span>
              <WrappedMessage message={messages.salarySliderEntryLevelYearText} />
            </div>
            <div className='level'>
              <span className='highlight'>
                <WrappedMessage message={messages.salarySliderMidLevelText} />
              </span>
              <WrappedMessage message={messages.salarySliderMidLevelYearText} />
            </div>
            <div className='level'>
              <span className='highlight'>
                <WrappedMessage message={messages.salarySliderSeniorLevelText} />
              </span>
              <WrappedMessage message={messages.salarySliderSeniorLevelYearText} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


interface CareerSalaryProps {
  entryLevelSalary: number | undefined;
  midLevelSalary: number | undefined;
  seniorLevelSalary: number | undefined;
}

const CareerSalary: React.FC<CareerSalaryProps> = ({
  entryLevelSalary,
  midLevelSalary,
  seniorLevelSalary,
}) => {
  return (
    <div className='career-salary-container'>
      <div className='title-container'>
        <div className='title'>
          <WrappedMessage message={messages.salarySectionHeading} />
        </div>
      </div>
      <SalaryCard salaryLevels={{entry: entryLevelSalary, mid: midLevelSalary, senior: seniorLevelSalary}} />
      <div className='footer-text'>
        <span className='highlight'><WrappedMessage message={messages.salaryFooterTextPart1} /> </span>
        <span><WrappedMessage message={messages.salaryFooterTextPart2} /></span>
      </div>
    </div>
  );
};

export default CareerSalary;
