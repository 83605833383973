import React, { useEffect } from 'react';
import { Icon } from '../../../../../elements';
import classNames from 'classnames';

interface SideBarParentProps {
    heading: string;
    description: string;
    children?: React.ReactNode;
    onClose: () => void;
    isOpen: boolean;
    backgroundColor?: string;
    iconComponent?: React.ReactNode;
    isFullScreen?: boolean;
}

const SideBarParent: React.FC<SideBarParentProps> = ({
 heading,
 description,
 children,
 onClose,
 isOpen,
 iconComponent,
 isFullScreen
}) => {
    return (
        <div className={classNames('career-sidebar', {'open': isOpen, 'closed': !isOpen, 'open-fullscreen': isFullScreen})}>
            <div className='career-sidebar__header'>
                <div className='career-sidebar__header__content'>
                    <div className='icon-heading'>
                        {iconComponent}
                        <h1>{heading}</h1>
                    </div>

                    <button onClick={onClose} className='close-button'>
                        <Icon name='clear' fill='#00AEEF'/>
                    </button>
                </div>
            </div>
            <div className='career-sidebar__sidebar-main-content'>
                <div className='description'>
                    {description}
                </div>
                {children}
            </div>
        </div>
    );
};

SideBarParent.defaultProps = {
    backgroundColor: '#E7EDF1',
};

export default SideBarParent;
