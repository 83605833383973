import { defineMessages } from 'react-intl';

const messages = defineMessages({
    acceptCookiePolicy: {
        id:'acceptCookiePolicy',
        defaultMessage: 'Accept',
        description: 'Button text to accept cookie policy',
    },
    lxAuthActivateEmailBannerContent: {
        id: 'lxAuthActivateEmailBannerContent',
        defaultMessage: 'You still need to activate your account. {link}.',
        description: 'Content of the banner that appears when the user needs to activate his account',
    },
    lxAuthActivateEmailBannerLinkLabel: {
        id: 'lxAuthActivateEmailBannerLinkLabel',
        defaultMessage: 'Activate it now',
        description: 'Link label of the activate email banner',
    },
    policyAndTermsUpdateBannerContent: {
        id: 'policyAndTermsUpdateBannerContent',
        defaultMessage: 'LabXchange has updated its {policyLink}. By continuing to use our services, you acknowledge these updates.',
        description: 'Content of the banner that appears when terms and policies update',
    },
    policyLinkLabel: {
        id: 'policyLinkLabel',
        defaultMessage: 'Privacy Notice',
        description: 'Link label of the policies updates banner',
    },
    termsLinkLabel: {
        id: 'termsLinkLabel',
        defaultMessage: 'Terms of Service',
        description: 'Link label of the terms updates banner',
    },
    acceptTermsButton: {
        id: 'acceptTermsButton',
        defaultMessage: 'Accept',
        description: 'Text for button shown on a banner to accept changes to legal terms and policies',
    },
});

export default messages;
