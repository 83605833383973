import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { intl } from 'i18n';
import classNames from 'classnames';

import { ModuleHierarchy, ItemMetadata, TaggedContentRequest } from 'labxchange-client';
import { Icon, Modal } from 'elements';
import {
  Button, Spinner, TreeDropdown,
  TextBox,
} from 'ui/components';
import { getUserEmail } from 'auth/selectors';
import { detailUrlForEntity } from 'library/utils';
import { TaggedContentsApi } from 'global/api';

import { SelectDropdownOption } from '../../../types';
import messages from '../../../displayMessages';

export enum IS_ASSET_RELEVANT {
  YES = 'Yes',
  NO = 'No',
}

interface TagContentModalProps {
  item: ItemMetadata;
  curriculum: SelectDropdownOption;
  subject: SelectDropdownOption;
  modules: ModuleHierarchy[];
  expandModules?: boolean;
  onClose: () => void;
  onSubmit: (data: TaggedContentRequest) => Promise<void>;
}

export const TagContentModal: FC<TagContentModalProps> = ({
  item, curriculum, subject, modules, onClose, onSubmit, expandModules = false,
}) => {
  const userEmail = useSelector(getUserEmail);
  const [isAssetRelevant, setIsAssetRelevant] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedModules, setSelectedModules] = useState<string[]>([]);
  const [tellUsMoreFeedback, setTellUsMoreFeedback] = useState<string>('');
  const [preSelectedModules, setPreSelectedModules] = useState<string[]>([]);
  const modulesSelected = selectedModules.length > 0;

  useEffect(() => {
    const fetchTaggedContent = async () => {
      try {
        setLoading(true);
        const response = await TaggedContentsApi.contentsGetTaggedContent({
          userEmail,
          assetId: item.id,
          curriculumSlug: curriculum.value,
          subjectSlug: subject.value,
        });
        setIsAssetRelevant(response.isAssetRelevant ? IS_ASSET_RELEVANT.YES : IS_ASSET_RELEVANT.NO);
        setPreSelectedModules(response.modules || []);
        setTellUsMoreFeedback(response.tellUsMoreFeedback || '');
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchTaggedContent();
  }, []);

  let submitDisabled = true;
  if (isAssetRelevant === IS_ASSET_RELEVANT.YES && modulesSelected) {
    submitDisabled = false;
  } else if (isAssetRelevant === IS_ASSET_RELEVANT.NO) {
    submitDisabled = false;
  }

  const onClickSubmitButton = async () => {
    setLoading(true);
    const submitData = {
      userEmail,
      itemId: item.id,
      curriculum: curriculum.value,
      subject: subject.value,
      isAssetRelevant: isAssetRelevant === IS_ASSET_RELEVANT.YES,
      modules: selectedModules,
      tellUsMoreFeedback,
    };
    await onSubmit(submitData);
    setLoading(false);
    onClose();
  };

  const onModuleChange = (currentNode: any, selectedNodes: any) => {
    setSelectedModules(selectedNodes.map((node: any) => node.value));
  };

  const formatModules: any = (modulesArr: ModuleHierarchy[]) => {
    return modulesArr.map((moduleItem: any) => {
      const moduleItemValue = `${moduleItem.gradeSlug || moduleItem.grade_slug}|${moduleItem.slug}`;
      return {
        label: moduleItem.name,
        value: moduleItemValue,
        children: formatModules(moduleItem.subModules || moduleItem.sub_modules || []),
        expanded: expandModules,
        checked: preSelectedModules.includes(moduleItemValue),
      };
    });
  };

  const formatModulesByGrade: any = (modulesArr: ModuleHierarchy[]) => {
    const groupedByGrade: any = modulesArr.reduce((acc: any, moduleItem: any) => {
      const grade = moduleItem.gradeName || 'Ungraded';
      if (!acc[grade]) {
        acc[grade] = [];
      }
      acc[grade].push(moduleItem);
      return acc;
    }, {});

    return Object.keys(groupedByGrade).map((gradeName) => ({
      label: gradeName,
      value: gradeName,
      expanded: expandModules,
      children: groupedByGrade[gradeName].map((moduleItem: any) => {
        const moduleItemValue = `${moduleItem.gradeSlug || moduleItem.grade_slug}|${moduleItem.slug}`;
        return {
          label: moduleItem.name,
          value: `${moduleItem.gradeSlug || moduleItem.grade_slug}|${moduleItem.slug}`,
          children: formatModules(moduleItem.subModules || moduleItem.sub_modules || []),
          checked: preSelectedModules.includes(moduleItemValue),
        };
      }),
    }));
  };

  let modalContent = (
    <div className='tag-content-modal-body'>
      <h3 className='fb-mdl-title font-m-lt'>
        {intl.formatMessage(messages.tagContentModalTitle)}
      </h3>
      <>
        <div className='form'>
          <div className='fb-mdl-section'>
            <span className='font-xxs-lt'>
              {intl.formatMessage(messages.tagContentModalAssetLabel)}
            </span>
            <p>
              <a href={`${detailUrlForEntity(item)}`} target='_blank' rel='noreferrer'>
                {item.title}
              </a>
            </p>
            <span className='font-xxs-lt'>
              {intl.formatMessage(messages.tagContentModalCurriculumLabel)}
            </span>
            <p>{curriculum.label}</p>
            <span className='font-xxs-lt'>
              {intl.formatMessage(messages.tagContentModalSubjectLabel)}
            </span>
            <p>{subject.label}</p>
          </div>
          <div className='fb-mdl-section'>
            <span className='font-xxs-lt'>
              {intl.formatMessage(messages.tagContentModalIsRelevantQuestion)}
            </span>
            <div className='fb-mdl-buttons'>
              <button
                className={classNames('fb-mdl-btn',
                  { 'is-active': isAssetRelevant === IS_ASSET_RELEVANT.YES },
                )}
                aria-label={intl.formatMessage(messages.tagContentModalYesBtnLabel)}
                onClick={() => {setIsAssetRelevant(IS_ASSET_RELEVANT.YES); setSelectedModules([]);}}
              >
                <Icon name='thumbs-up' />
                <span>{intl.formatMessage(messages.tagContentModalYesBtnLabel)}</span>
              </button>
              <button
                className={classNames('fb-mdl-btn no-button',
                  { 'is-active': isAssetRelevant === IS_ASSET_RELEVANT.NO },
                )}
                aria-label={intl.formatMessage(messages.tagContentModalNoBtnLabel)}
                onClick={() => {setIsAssetRelevant(IS_ASSET_RELEVANT.NO); setSelectedModules([]);}}
              >
                <Icon name='thumbs-down' />
                <span>{intl.formatMessage(messages.tagContentModalNoBtnLabel)}</span>
              </button>
            </div>
          </div>
          {isAssetRelevant === IS_ASSET_RELEVANT.YES && (
            <div className='fb-mdl-section'>
              <span className='font-xxs-lt'>
                {intl.formatMessage(messages.tagContentModalCurriculumQuestion)}
              </span>
              <TreeDropdown
                data={formatModulesByGrade(modules)}
                onChange={onModuleChange}
                texts={{
                  placeholder: intl.formatMessage(messages.tagContentModalPODropdownPlaceholder),
                  inlineSearchPlaceholder: intl.formatMessage(
                    messages.tagContentModalPODropdownSearchPlaceholder
                  ),
                }}
                mode='hierarchical'
                showPartiallySelected={false}
              />
            </div>
          )}
          <div className='fb-mdl-section'>
            <span className='font-xxs-lt'>
              {intl.formatMessage(messages.tagContentModalTellUsMoreOptionalLabel)}
            </span>
            <div className='please-tell-us-more-opt'>
              <TextBox
                value={tellUsMoreFeedback}
                label={intl.formatMessage(messages.tagContentModalTellUsMoreLabel)}
                hideLabel={true}
                onChange={(currentValue) => setTellUsMoreFeedback(currentValue)}
                placeholder={intl.formatMessage(messages.tagContentModalTellUsMoreLabel)}
              />
            </div>
          </div>
        </div>
        <div className='fb-mdl-footer'>
          <Button
            className='fb-mdl-submit-btn'
            btnStyle='primary'
            aria-label={messages.tagContentModalSubmitBtnLabel}
            label={messages.tagContentModalSubmitBtnLabel}
            disabled={submitDisabled || loading}
            onClick={onClickSubmitButton}
          />
        </div>
      </>
    </div>
  );

  if (loading) {
    modalContent = <div className='spinner-wrapper'><Spinner /></div>;
  }

  return (
    <Modal
      className='tag-content-modal'
      size='normal'
      topBarStyle='white'
      showBackButton={false}
      onRequestClose={onClose}
      showTopBar={true}
      content={modalContent}
    />
  );
};

export default TagContentModal;
