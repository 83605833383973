// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    CareerOutlookFact,
    CareerOutlookFactFromJSON,
    CareerOutlookFactToJSON,
} from './';

/**
 * 
 * @export
 * @interface CareerOutlook
 */
export interface CareerOutlook {
    /**
     * Get related facts.
     * @type {Array<CareerOutlookFact>}
     * @memberof CareerOutlook
     */
    readonly facts?: Array<CareerOutlookFact>;
    /**
     * 
     * @type {string}
     * @memberof CareerOutlook
     */
    demandLabel: string;
    /**
     * 
     * @type {number}
     * @memberof CareerOutlook
     */
    jobOpenings?: number;
}

export function CareerOutlookFromJSON(json: any): CareerOutlook {
    return {
        'facts': !exists(json, 'facts') ? undefined : (json['facts'] as Array<any>).map(CareerOutlookFactFromJSON),
        'demandLabel': json['demand_label'],
        'jobOpenings': !exists(json, 'job_openings') ? undefined : json['job_openings'],
    };
}

export function CareerOutlookToJSON(value?: CareerOutlook): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'demand_label': value.demandLabel,
        'job_openings': value.jobOpenings,
    };
}


